* {
    box-sizing: border-box;
}
html {
    overflow-y: scroll;
}
.hidden {
    display: none;
}
/*
.MuiCheckbox-root .MuiSvgIcon-root{
    font-size: 2rem;
}
*/
.MuiCheckbox-root .MuiIconButton-label {
    line-height: 0.1;
}

/*
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 64px #f2f4f5  inset !important;
}*/

.ScrollbarsCustom-TrackX {
    height: 4px !important;
    background: #dadada !important;
    border-radius: 10px !important;
    transition: 0.1s all ease-in;
    left: 0 !important;
    width: 100% !important;
}
/*
.ScrollbarsCustom-TrackX:hover{
    height: 10px !important;    
}
*/
.ScrollbarsCustom-ThumbX {
    background: #9299a2 !important;
    border-radius: 10px !important;
}
.ScrollbarsCustom-ThumbX:hover {
    background: #666 !important;
}

.ScrollbarsCustom-TrackY {
    width: 4px !important;
    background: #dadada !important;
    border-radius: 10px !important;
    transition: 0.1s all ease-in;
}
.ScrollbarsCustom-TrackY:hover {
    width: 10px !important;
}
.ScrollbarsCustom-ThumbY {
    background: #9299a2 !important;
    border-radius: 10px !important;
}
.ScrollbarsCustom-ThumbY:hover {
    background: #666 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.ScrollbarsCustom-Content {
    display: block !important;
    width: 100%;
}
.ScrollbarsCustom.trackXVisible::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 15px;
    width: 120px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff);
}
.ScrollbarsCustom-TrackX__pre {
    display: none;
    position: absolute;
    overflow: hidden;
    user-select: none;
    height: 15px;
    width: 100%; /* calc(100% - 20px); */
    bottom: 0px;
    left: 0px; /* 10px */
    cursor: pointer;
}
.ScrollbarsCustom-TrackX__pre:hover .ScrollbarsCustom-TrackX {
    height: 10px !important;
}
.scrollXend .ScrollbarsCustom.trackXVisible::after {
    display: none;
}
.ScrollbarsCustom.trackXVisible .ScrollbarsCustom-TrackX__pre {
    display: block;
}

.MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 4px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-track {
    background: #fafafa;
    border-radius: 4px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
    background: #c4c4c4;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
    background: #b4b4b4;
}

.MuiFilledInput-underline.Mui-error:after,
.MuiFilledInput-underline.Mui-focused:after {
    transform: scaleX(0);
}
/*
.MuiFilledInput-underline:before{
    display: none;
}
.MuiFilledInput-underline:after{
    border: 1px solid #F2F4F5;
}
*/
.MuiFormControl-root > .MuiFormHelperText-root.Mui-error {
    display: none;
}

.grecaptcha-badge {
    visibility: hidden;
}

@media(max-width: 479px) {
  .MuiDialog-paperFullScreen{
    height: auto;
  }
  .MuiTableCell-root{
    padding: 12px 8px; 
  }
  .MuiTablePagination-spacer + .MuiTablePagination-caption{
    display: none;
  }
  .MuiTablePagination-root .MuiToolbar-gutters{
    padding: 0;
  }
  .MuiTablePagination-root .MuiIconButton-root{
    padding: 5px;
  }
}
